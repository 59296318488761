import React from 'react';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Input, Text } from '@web-for-marketing/react-ui';
import { roiAdvanceFormStyles as classes } from './styleObjects/ROIAdvanceFormStyles';
import {
    costPerGallon,
    costPerLiter,
    kilometerPerLiter,
    kilometerToMiles,
    milePerGallon,
    milesToKilometer,
} from './ROICalculationHelper';
import { roiIndustryValueAtom, roiUnitSystemAtom } from '@atoms/roiCalculator';
import { useAtom } from 'jotai';
import { Unit } from '@models/ROICalculator';
import { capitalizeFirstLetter } from '@helpers/string';

export default function ROIAdvanceForm(): JSX.Element {
    const [unitSystem, setUnitSystem] = useAtom(roiUnitSystemAtom);
    const [{ advancedFormDefaultValue }, setIndustryFormValue] = useAtom(roiIndustryValueAtom);

    const unit =
        unitSystem === Unit.Imperial
            ? {
                distance: 'miles',
                volume: 'gallon',
            }
            : {
                distance: 'kilometers',
                volume: 'liter',
            };

    const handleOnUnitChange = (event: React.MouseEvent<HTMLElement>, newValue: Unit): void => {
        setUnitSystem(newValue);
        setIndustryFormValue((prevValue) => {
            const { advancedFormDefaultValue } = prevValue;
            let formDefaultValue;

            if (newValue === Unit.Imperial) {
                formDefaultValue = {
                    fuelCostPerVolume: costPerGallon(advancedFormDefaultValue.fuelCostPerVolume),
                    fuelEconomy: milePerGallon(advancedFormDefaultValue.fuelEconomy),
                    distancePerDay: kilometerToMiles(advancedFormDefaultValue.distancePerDay),
                };
            } else {
                formDefaultValue = {
                    fuelCostPerVolume: costPerLiter(advancedFormDefaultValue.fuelCostPerVolume),
                    fuelEconomy: kilometerPerLiter(advancedFormDefaultValue.fuelEconomy),
                    distancePerDay: milesToKilometer(advancedFormDefaultValue.distancePerDay),
                };
            }

            return {
                ...prevValue,
                advancedFormDefaultValue: {
                    ...advancedFormDefaultValue,
                    ...formDefaultValue,
                },
            };
        });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Text color='primary' variant='body2' css={classes.inputLabel} id='toggle-button-group-unit-label'>
                    Unit Type
                </Text>
                <ToggleButtonGroup
                    exclusive
                    value={unitSystem}
                    onChange={handleOnUnitChange}
                    aria-labelledby='toggle-button-group-unit-label'
                    css={classes.toggleButtonGroup}
                >
                    <ToggleButton
                        value={Unit.Imperial}
                        css={classes.toggleButton}
                        selected={unitSystem === Unit.Imperial}
                        disabled={unitSystem === Unit.Imperial}
                    >
                        {Unit.Imperial}
                    </ToggleButton>
                    <ToggleButton
                        value={Unit.Metric}
                        css={classes.toggleButton}
                        selected={unitSystem === Unit.Metric}
                        disabled={unitSystem === Unit.Metric}
                    >
                        {Unit.Metric}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} css={classes.formInputContainer}>
                <Text color='primary' variant='body2' css={classes.inputLabel}>
                    Fuel cost per {unit.volume}
                </Text>
                <Input
                    type='number'
                    min={0}
                    required
                    hiddenLabel
                    label={`Fuel cost per ${unit.volume}`}
                    placeholder={`Enter fuel cost per ${unit.volume}...`}
                    id={`ROI-fuel-cosy-per-${unit.volume}`}
                    css={classes.formInput}
                    value={advancedFormDefaultValue.fuelCostPerVolume.toString()}
                    onChange={(e) =>
                        setIndustryFormValue((prevValue) => ({
                            ...prevValue,
                            advancedFormDefaultValue: {
                                ...prevValue.advancedFormDefaultValue,
                                fuelCostPerVolume: parseFloat(e.target.value),
                            },
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} css={classes.formInputContainer}>
                <Text color='primary' variant='body2' css={classes.inputLabel}>
                    {capitalizeFirstLetter(unit.distance)} per {unit.volume}
                </Text>
                <Input
                    type='number'
                    min={0}
                    required
                    hiddenLabel
                    label={`${capitalizeFirstLetter(unit.distance)} per ${unit.volume}`}
                    placeholder={`Enter ${unit.distance} per ${unit.volume}...`}
                    id={`ROI-${unit.distance}-per-${unit.volume}`}
                    css={classes.formInput}
                    value={advancedFormDefaultValue.fuelEconomy.toString()}
                    onChange={(e) =>
                        setIndustryFormValue((prevValue) => ({
                            ...prevValue,
                            advancedFormDefaultValue: {
                                ...prevValue.advancedFormDefaultValue,
                                fuelEconomy: parseFloat(e.target.value),
                            },
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} css={classes.formInputContainer}>
                <Text color='primary' variant='body2' css={classes.inputLabel}>
                    Average {unit.distance} per day
                </Text>
                <Input
                    type='number'
                    min={0}
                    required
                    hiddenLabel
                    label={`Average ${unit.distance} per day`}
                    placeholder={`Enter average ${unit.distance} per day...`}
                    id={`ROI-average-${unit.distance}-per-day`}
                    css={classes.formInput}
                    value={advancedFormDefaultValue.distancePerDay.toString()}
                    onChange={(e) =>
                        setIndustryFormValue((prevValue) => ({
                            ...prevValue,
                            advancedFormDefaultValue: {
                                ...prevValue.advancedFormDefaultValue,
                                distancePerDay: parseFloat(e.target.value),
                            },
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} css={classes.formInputContainer}>
                <Text color='primary' variant='body2' css={classes.inputLabel}>
                    Hourly rate
                </Text>
                <Input
                    type='number'
                    min={0}
                    required
                    hiddenLabel
                    label='Hourly rate'
                    placeholder='Enter hourly rate...'
                    id='ROI-hourly-rate'
                    css={classes.formInput}
                    value={advancedFormDefaultValue.hourlyRate.toString()}
                    onChange={(e) =>
                        setIndustryFormValue((prevValue) => ({
                            ...prevValue,
                            advancedFormDefaultValue: {
                                ...prevValue.advancedFormDefaultValue,
                                hourlyRate: parseFloat(e.target.value),
                            },
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} css={classes.formInputContainer}>
                <Text color='primary' variant='body2' css={classes.inputLabel}>
                    Average overtime per day
                </Text>
                <Input
                    type='number'
                    min={0}
                    required
                    hiddenLabel
                    label='Average overtime per day'
                    placeholder='Enter average overtime per day...'
                    id='ROI-average-overtime-per-day'
                    css={classes.formInput}
                    endAdornment='Minutes'
                    value={advancedFormDefaultValue.averageOvertimePerDay.toString()}
                    onChange={(e) =>
                        setIndustryFormValue((prevValue) => ({
                            ...prevValue,
                            advancedFormDefaultValue: {
                                ...prevValue.advancedFormDefaultValue,
                                averageOvertimePerDay: parseFloat(e.target.value),
                            },
                        }))
                    }
                />
            </Grid>
        </Grid>
    );
}
