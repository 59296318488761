export type ROIIndustry = 'Telecom' | 'Pest' | 'Lawn' | 'Government' | 'Health' | 'Linen' | 'Maintenance' | 'Delivery';
export interface ROIIndustryOption {
    name: ROIIndustry;
    defaultValue: ROIBasicFormValue;
    advancedFormDefaultValue: ROIAdvanceFormValue;
    savingsAssumption: ROISavingsAssumption;
}

export interface ROIBasicFormValue {
    numberOfVehicles: number;
    averageVisitsPerDay: number;
    averageVisitDuration: number;
    averageRevenuePerVisit: number;
}

export interface ROIAdvanceFormValue {
    fuelCostPerVolume: number;
    fuelEconomy: number;
    distancePerDay: number;
    hourlyRate: number;
    averageOvertimePerDay: number;
}

export interface ROISavingsAssumption {
    distanceSaving: number;
    travelDurationSaving: number;
    overTimeSaving: number;
    monthSubscriptionCostPerVehicle: number;
    collisionRatePerMillionMiles: number;
    costPerCollision: number;
    vehicleCostPerMile: number;
    co2EmissionPerGallonInTon: number;
}

export enum Duration {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year',
}

export enum Unit {
    Imperial = 'Imperial',
    Metric = 'Metric',
}
