const likedBlogsKey = 'likedBlogs';
const adminLanguage = 'adminLanguage';

export function addLikedBlog(blogId) {
    var likedBlogsArray = getLocalStorageItem(likedBlogsKey);
    if (likedBlogsArray === null) {
        likedBlogsArray = [blogId];
    } else {
        likedBlogsArray = JSON.parse(likedBlogsArray);
        if (!likedBlogsArray.includes(blogId)) {
            likedBlogsArray.push(blogId);
        }
    }
    setLocalStorageItem(likedBlogsKey, JSON.stringify(likedBlogsArray));
}

export function isBlogLiked(blogId) {
    var likedBlogsArray = getLocalStorageItem(likedBlogsKey);
    return likedBlogsArray !== null && JSON.parse(likedBlogsArray).includes(blogId);
}

export function setAdminLanguage(language) {
    setLocalStorageItem(adminLanguage, JSON.stringify(language));
}

export function getAdminLanguage() {
    var language = getLocalStorageItem(adminLanguage);
    return language ? JSON.parse(language) : null;
}

export function setLocalStorageItem(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        //
    }
}

export function getLocalStorageItem(key) {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        return null;
    }
}

export function removeLocalStorageItem(key) {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        //
    }
}

export function clearLocalStorageItems() {
    try {
        localStorage.clear();
    } catch (error) {
        //
    }
}
