import React, { useMemo } from 'react';
import { customSectionTypes } from '../EditorFormComponents/helpers/CustomSectionTypes';
import { Grid } from '@mui/material';
import { v2Colors } from '@web-for-marketing/react-core';
import { Heading } from '@web-for-marketing/react-ui';
import { CustomSectionContent } from '@models/section/customSection';

const classes = {
    customSectionComponentContainer: {
        minHeight: '10rem',
    },
    notConfiguredContainer: {
        backgroundColor: v2Colors.core.cloud,
    },
    notConfiguredHeading: {
        margin: '8rem auto',
    },
};

type CustomSectionProps = CustomSectionContent & {
    lazyLoad?: boolean;
    headerSection?: boolean;
};

export function CustomSection({ component, params, lazyLoad = false, headerSection }: CustomSectionProps): JSX.Element {
    const CustomSectionComponent = useMemo(() => {
        const sectionType = customSectionTypes.find((sectionType) => sectionType.value === component);
        return (sectionType && sectionType.component) || null;
    }, [component]);

    return (
        <>
            {CustomSectionComponent ? (
                <Grid container css={classes.customSectionComponentContainer}>
                    <CustomSectionComponent params={params} lazyLoad={lazyLoad} headerSection={headerSection} />
                </Grid>
            ) : (
                <Grid container alignContent='center' css={classes.notConfiguredContainer}>
                    <Heading variant='h2' color='primary' css={classes.notConfiguredHeading}>
                        Custom section not configured
                    </Heading>
                </Grid>
            )}
        </>
    );
}

export default CustomSection;
