export const MileToKilometer = 1.609344;
export const GallonToLiter = 3.785411784;
export const MilePerGallonToKilomterPerLiter = 2.3521458;
export const MilesPerHour = 40;
export const KilometerPerHour = 64.37376;

export const HoursInDay = 8;
export const MinutesPerHour = 60;
export const HoursInMonth = 173.3;
export const HoursInYear = 2080;
