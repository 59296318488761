import { Duration, ROIIndustryOption, Unit } from '@models/ROICalculator';
import {
    GallonToLiter,
    HoursInMonth,
    KilometerPerHour,
    MilePerGallonToKilomterPerLiter,
    MileToKilometer,
    MilesPerHour,
    MinutesPerHour,
} from './ROIConstants';

export const costPerLiter = (costPerLiter: number): number => {
    return costPerLiter / GallonToLiter;
};

export const costPerGallon = (costPerGallon: number): number => {
    return costPerGallon * GallonToLiter;
};

export const milePerGallon = (kmPerLiter: number): number => {
    return kmPerLiter * MilePerGallonToKilomterPerLiter;
};

export const kilometerPerLiter = (milePerGallon: number): number => {
    return milePerGallon * (1 / MilePerGallonToKilomterPerLiter);
};

export const milesToKilometer = (miles: number): number => {
    return miles * MileToKilometer;
};

export const kilometerToMiles = (km: number): number => {
    return km * (1 / MileToKilometer);
};

export const roundTwoDecimal = (value: number): string =>
    (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString();

export const roundToLocalString = (value: number): string => Math.round(value).toLocaleString();

interface ROIResult {
    profitAdded: number;
    revenueAdded: number;
    costSaved: number;
    hoursSaved: number;
    distanceSaved: number;
    estimatedROI: number;
    timeToPayback: number;
    fuelSaved: number;
    fuelCostsSaved: number;
    emissionSaved: number;
    overtimeHoursSaved: number;
}

export const getROIResultByDuration = (
    duration: Duration,
    industryValue: ROIIndustryOption,
    unitSystem: Unit
): ROIResult => calculateROI(industryValue, getNumberOfHours(duration), unitSystem);

const getNumberOfHours = (duration: Duration): number => {
    switch (duration) {
        case Duration.Day:
            return 8;
        case Duration.Week:
            return 40;
        case Duration.Month:
            return 173.3;
        case Duration.Year:
            return 2080;
        default:
            return 0;
    }
};

export const calculateROI = (
    { defaultValue, advancedFormDefaultValue, savingsAssumption }: ROIIndustryOption,
    numberOfHours: number,
    unitSystem: Unit
): ROIResult => {
    const {
        distanceSaving,
        collisionRatePerMillionMiles,
        costPerCollision,
        vehicleCostPerMile,
        overTimeSaving,
        monthSubscriptionCostPerVehicle,
        travelDurationSaving,
    } = savingsAssumption;

    const { distancePerDay, averageOvertimePerDay, fuelCostPerVolume, fuelEconomy, hourlyRate } =
        advancedFormDefaultValue;

    const { numberOfVehicles, averageVisitsPerDay, averageVisitDuration, averageRevenuePerVisit } = defaultValue;

    const vehicleCostPerDistance =
        unitSystem === Unit.Imperial ? vehicleCostPerMile : vehicleCostPerMile / MileToKilometer;

    const distancePerHour = unitSystem === Unit.Imperial ? MilesPerHour : KilometerPerHour;

    const minutesSavedDaily = (distancePerDay / distancePerHour) * MinutesPerHour * distanceSaving;

    const hoursSaved = (minutesSavedDaily / MinutesPerHour) * numberOfVehicles * 22;

    const avgTripVisitDurationInMinutes =
        averageVisitDuration +
        (distancePerDay / distancePerHour) * (MinutesPerHour / averageVisitsPerDay) * (1 - travelDurationSaving);

    const jobsAdded = (minutesSavedDaily / avgTripVisitDurationInMinutes) * numberOfVehicles * 22;

    const revenueAdded = jobsAdded * averageRevenuePerVisit;

    const distanceSaved = distancePerDay * numberOfVehicles * distanceSaving * 22;

    const overtimeHoursSaved = (averageOvertimePerDay / MinutesPerHour) * overTimeSaving * numberOfVehicles * 22;

    const statusSavings = numberOfVehicles * 25;

    const costSaved =
        (distanceSaved * fuelCostPerVolume) / fuelEconomy +
        (collisionRatePerMillionMiles / 1000000) * costPerCollision * distanceSaved +
        distanceSaved * vehicleCostPerDistance +
        overtimeHoursSaved * hourlyRate * 1.5 +
        statusSavings;

    const profitAdded = revenueAdded + costSaved;

    const estimatedROI =
        ((profitAdded - numberOfVehicles * monthSubscriptionCostPerVehicle) /
            (numberOfVehicles * monthSubscriptionCostPerVehicle)) *
        100;

    const timeToPayback = (monthSubscriptionCostPerVehicle * numberOfVehicles * 22) / profitAdded;

    const fuelSaved = distanceSaved / fuelEconomy;

    const fuelCostsSaved = (distanceSaved / fuelEconomy) * fuelCostPerVolume;

    const emissionSaved = distanceSaving * 100;

    const timeSpanProfitAdded = (profitAdded / HoursInMonth) * numberOfHours;
    const timeSpanRevenueAdded = (revenueAdded / HoursInMonth) * numberOfHours;
    const timeSpanCostSaved = (costSaved / HoursInMonth) * numberOfHours;
    const timeSpanHoursSaved = (hoursSaved / HoursInMonth) * numberOfHours;
    const timeSpanDistanceSaved = (distanceSaved / HoursInMonth) * numberOfHours;
    const timeSpanTimeToPayback = (timeToPayback / HoursInMonth) * numberOfHours;
    const timeSpanFuelSaved = (fuelSaved / HoursInMonth) * numberOfHours;
    const timeSpanFuelCostsSaved = (fuelCostsSaved / HoursInMonth) * numberOfHours;
    const timeSpanOvertimeHoursSaved = (overtimeHoursSaved / HoursInMonth) * numberOfHours;

    return {
        profitAdded: isNaN(timeSpanProfitAdded) ? 0 : Math.max(timeSpanProfitAdded, 0),
        revenueAdded: isNaN(timeSpanRevenueAdded) ? 0 : Math.max(timeSpanRevenueAdded, 0),
        costSaved: isNaN(timeSpanCostSaved) ? 0 : Math.max(timeSpanCostSaved, 0),
        hoursSaved: isNaN(timeSpanHoursSaved) ? 0 : Math.max(timeSpanHoursSaved, 0),
        distanceSaved: isNaN(timeSpanDistanceSaved) ? 0 : Math.max(timeSpanDistanceSaved, 0),
        estimatedROI: isNaN(estimatedROI) ? 0 : Math.max(estimatedROI, 0),
        timeToPayback: isNaN(timeSpanTimeToPayback) ? 0 : Math.max(timeSpanTimeToPayback, 0),
        fuelSaved: isNaN(timeSpanFuelSaved) ? 0 : Math.max(timeSpanFuelSaved, 0),
        fuelCostsSaved: isNaN(timeSpanFuelCostsSaved) ? 0 : Math.max(timeSpanFuelCostsSaved, 0),
        emissionSaved: timeSpanCostSaved ? emissionSaved : 0,
        overtimeHoursSaved: isNaN(timeSpanOvertimeHoursSaved) ? 0 : Math.max(timeSpanOvertimeHoursSaved, 0),
    };
};

export function getROIPaybackTime(days: number): string {
    const daysToPayback = Math.ceil(days);

    if (daysToPayback <= 180) {
        return 'Less than six months';
    } else if (daysToPayback <= 365) {
        return 'Less than one year';
    } else if (daysToPayback > 365 && daysToPayback < 730) {
        return `One year and ${daysToPayback - 365} days`;
    } else {
        return 'More than 1 year';
    }
}
