import React from 'react';
import { GridContainer } from '@components/GeotabGridComponents';
import { Grid } from '@mui/material';
import ROIResult from './ROICalculator/ROIResult';
import ROIForm from './ROICalculator/ROIForm';
import { CustomSectionComponentProps } from '@models/customSection';
import { Text } from '@web-for-marketing/react-ui';

const classes = {
    twoColumnResultContainer: {
        padding: '2rem 4.6rem',
    },
};

export default function ROICalculator({ headerSection }: CustomSectionComponentProps): JSX.Element {
    return (
        <GridContainer alignItems='start'>
            <ROIForm headerSection={headerSection} />
            <Grid item xs={12} md={6} css={classes.twoColumnResultContainer}>
                <ROIResult />
            </Grid>
            <Text color='primary' variant='body2' italic css={{ marginTop: '2.4rem' }}>
                Our ROI calculator uses advanced modelling and 20 years of evolution to offer accurate projections.
            </Text>
        </GridContainer>
    );
}
