import { ROIIndustryOption } from '@models/ROICalculator';

export const roiIndustryOptions: ROIIndustryOption[] = [
    {
        name: 'Telecom',
        defaultValue: {
            numberOfVehicles: 600,
            averageVisitsPerDay: 6,
            averageVisitDuration: 45,
            averageRevenuePerVisit: 85,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 15,
            distancePerDay: 81,
            hourlyRate: 30,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.1452,
            travelDurationSaving: 0.13,
            overTimeSaving: 0.5103,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Pest',
        defaultValue: {
            numberOfVehicles: 50,
            averageVisitsPerDay: 12,
            averageVisitDuration: 30,
            averageRevenuePerVisit: 100,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 15,
            distancePerDay: 130,
            hourlyRate: 25,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.3754,
            travelDurationSaving: 0.2496,
            overTimeSaving: 0.3511,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Lawn',
        defaultValue: {
            numberOfVehicles: 50,
            averageVisitsPerDay: 25,
            averageVisitDuration: 12,
            averageRevenuePerVisit: 50,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.78,
            fuelEconomy: 10,
            distancePerDay: 232.5,
            hourlyRate: 20,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.228,
            travelDurationSaving: 0.167,
            overTimeSaving: 0.344,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Government',
        defaultValue: {
            numberOfVehicles: 100,
            averageVisitsPerDay: 8,
            averageVisitDuration: 30,
            averageRevenuePerVisit: 75,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 15,
            distancePerDay: 118,
            hourlyRate: 25,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.1102,
            travelDurationSaving: 0.348,
            overTimeSaving: 0.0,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Health',
        defaultValue: {
            numberOfVehicles: 50,
            averageVisitsPerDay: 8,
            averageVisitDuration: 45,
            averageRevenuePerVisit: 125,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 20,
            distancePerDay: 118,
            hourlyRate: 40,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.1625,
            travelDurationSaving: 0.1345,
            overTimeSaving: 0.0,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Linen',
        defaultValue: {
            numberOfVehicles: 100,
            averageVisitsPerDay: 35,
            averageVisitDuration: 12,
            averageRevenuePerVisit: 65,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 12,
            distancePerDay: 170,
            hourlyRate: 25,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.244,
            travelDurationSaving: 0.264,
            overTimeSaving: 0.0,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Maintenance',
        defaultValue: {
            numberOfVehicles: 50,
            averageVisitsPerDay: 6,
            averageVisitDuration: 45,
            averageRevenuePerVisit: 100,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 12,
            distancePerDay: 101,
            hourlyRate: 30,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.3133,
            travelDurationSaving: 0.312,
            overTimeSaving: -0.1048,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
    {
        name: 'Delivery',
        defaultValue: {
            numberOfVehicles: 100,
            averageVisitsPerDay: 25,
            averageVisitDuration: 15,
            averageRevenuePerVisit: 75,
        },
        advancedFormDefaultValue: {
            fuelCostPerVolume: 3.2,
            fuelEconomy: 10,
            distancePerDay: 180,
            hourlyRate: 25,
            averageOvertimePerDay: 60,
        },
        savingsAssumption: {
            distanceSaving: 0.3511,
            travelDurationSaving: 0.2722,
            overTimeSaving: 0.0,
            monthSubscriptionCostPerVehicle: 40,
            collisionRatePerMillionMiles: 1.36,
            costPerCollision: 8400,
            vehicleCostPerMile: 0.37,
            co2EmissionPerGallonInTon: 0.0097,
        },
    },
];
