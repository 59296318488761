import { colors, breakpoints, v2Colors } from '@web-for-marketing/react-core';

export const roiResultStyles = {
    toggleButtonGroup: {
        marginBottom: '1.3rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            margin: '2.6rem 0',
        },
    },
    backgroundContainer: {
        backgroundColor: v2Colors.background.light,
        padding: '2.5rem 3.8rem',
        borderRadius: '0.8rem',
        height: '100%',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '2rem 2.4rem',
            borderRadius: '0.4rem',
        },
    } as const,
    twoColumnBackgroundContainer: {
        marginTop: '2.5rem',
        '&:nth-child(even)': {
            paddingLeft: '.85rem',
        },
        '&:nth-child(odd)': {
            paddingRight: '.85rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            '&:nth-child(even)': {
                paddingLeft: 0,
            },
            '&:nth-child(odd)': {
                paddingRight: 0,
            },
        },
    },
    twoColumnContainer: {
        padding: '2.5rem 3.8rem 0',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '2rem 2.4rem 0',
        },
    },
    buttonContainer: {
        marginTop: '2.5rem',
    },
    textMarginBottom: {
        marginBottom: '1rem',
    },
    toggleButton: {
        fontSize: '1.4rem',
        borderColor: 'transparent',
        color: v2Colors.text.activePrimary,
        backgroundColor: colors.interactive.inverse,
        borderRadius: '8px',
        padding: '.8rem 1.8rem',
        textTransform: 'none',
        '&.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
            borderColor: colors.interactive.primary,
        },
        '&:hover': {
            backgroundColor: v2Colors.core.cloud,
            borderColor: colors.interactive.primary,
        },
        '&:focus': {
            backgroundColor: v2Colors.core.cloud,
            borderColor: colors.interactive.primary,
        },
        '&.Mui-selected': {
            color: colors.interactive.inverse,
            backgroundColor: colors.interactive.primary,
            borderColor: colors.interactive.primary,
            '&:hover': {
                backgroundColor: colors.interactive.primary,
            },
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            borderRadius: '4px',
            padding: '0.8rem 1.2rem',
        },
        [`@media (min-width: ${breakpoints.sm}px)`]: {
            '&.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
                '&:not(:first-of-type)': {
                    borderRadius: '8px',
                    margin: '1.3rem',
                },
                '&:first-of-type': {
                    borderRadius: '8px',
                    margin: '1.3rem',
                },
            },
        },
    },
} as const;
