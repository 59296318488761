import React, { useState, useEffect, startTransition } from 'react';
import { Button, ButtonProps, Dialog, Grid, IconButton } from '@mui/material';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { CustomSection } from '@sections/CustomSection';
import { FormParams } from '@models/form';
import { CloseIcon } from '@icon/CloseIcon';
import { v2Colors, breakpoints } from '@web-for-marketing/react-core';
import { visuallyHidden } from '@mui/utils';
import { ClassNames } from '@emotion/react';
import { setLocalStorageItem } from '@services/localStorageService';
import SiteWideFormBackground from '../../images/SiteWideDemoForm/stiewide-demo-bg.jpg';

const closeButtonSize = '1.4rem';

const classes = {
    closePopoutButton: {
        marginRight: '2rem',
        marginTop: '2rem',
        padding: '1rem',
        boxSizing: 'content-box',
        height: closeButtonSize,
        width: closeButtonSize,
        color: v2Colors.core.blackTarr,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            backgroundColor: v2Colors.core.snow,
        },
    },
    closePopoutButtonBorder: {
        marginRight: '3.6rem',
        marginTop: '2rem',
        border: `0.1rem solid ${v2Colors.core.blackTarr}`,
    },
    fixedPopout: {
        boxShadow: 'none',
        backgroundColor: v2Colors.core.snow,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: 'auto',
            top: 0,
            height: '100% !important',
        },
    },
    siteWideDemoPopout: {
        boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.08)',
        background: `url(${SiteWideFormBackground}) no-repeat top / cover`,
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            backgroundPosition: 'top left',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            backgroundPosition: 'center',
        },
    },
    siteWideDemoPopoutPaper: {
        position: 'unset',
        minWidth: '599.9px',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '1.6rem',
            minWidth: 'unset',
        },
    },
    popoutContentContainer: {
        overflowY: 'auto',
        height: '100%',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '4rem',
        },
    },
} as const;

interface BaseProps {
    text: string;
    customSectionKey?: string;
    closeAdditional?: () => void;
    params?: FormParams;
    campaignId?: string;
}

type PopoutButtonProps = BaseProps & ButtonProps;

export function PopoutButton({
    text,
    customSectionKey,
    params = [],
    campaignId,
    closeAdditional,
    ...buttonProps
}: PopoutButtonProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [open, setOpen] = useState(false);

    const setPopoutOpen = (popoutValue: boolean): void => {
        setLocalStorageItem('campaignId', campaignId);

        if (closeAdditional) closeAdditional();
        startTransition(() => {
            setOpen(popoutValue);
        });
    };

    const handleClose = (): void => {
        setPopoutOpen(false);
    };

    useEffect(() => {
        if (open) {
            setPopoutOpen(false);
        }
        return () => {
            if (open) {
                setPopoutOpen(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <>
            <Button aria-label={`Open ${text} popout`} onClick={() => setPopoutOpen(true)} {...buttonProps}>
                {text}
            </Button>
            {open ? (
                customSectionKey === 'MultistepDemoForm' ? (
                    <ClassNames>
                        {({ css }) => (
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                css={classes.siteWideDemoPopout}
                                classes={{ paper: css(classes.siteWideDemoPopoutPaper) }}
                                aria-labelledby='popout-button-title'
                            >
                                <h2 style={visuallyHidden} id='popout-button-title'>
                                    {text}
                                </h2>
                                <Grid container justifyContent='flex-end'>
                                    <IconButton
                                        aria-label='Close dialog'
                                        css={classes.closePopoutButton}
                                        onClick={handleClose}
                                        size='large'
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <CustomSection component={customSectionKey} params={params} lazyLoad={false} />
                            </Dialog>
                        )}
                    </ClassNames>
                ) : (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        hideBackdrop
                        fullScreen
                        css={classes.fixedPopout}
                        aria-labelledby='popout-button-title'
                    >
                        <h2 style={visuallyHidden} id='popout-button-title'>
                            {text}
                        </h2>
                        <Grid container justifyContent='flex-end'>
                            <IconButton
                                aria-label='Close dialog'
                                css={[classes.closePopoutButton, classes.closePopoutButtonBorder]}
                                onClick={handleClose}
                                size='large'
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid container css={classes.popoutContentContainer} justifyContent='center'>
                            <CustomSection component={customSectionKey} params={params} lazyLoad={false} />
                        </Grid>
                    </Dialog>
                )
            ) : null}
        </>
    );
}
