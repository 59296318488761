import { breakpoints, v2Colors } from '@web-for-marketing/react-core';

export const roiFormStyles = {
    twoColumnFormContainer: {
        padding: '4.6rem 3%',
        borderRadius: '0.8rem',
        border: `1px solid ${v2Colors.border.activePrimary}`,
        borderTop: `2rem solid ${v2Colors.border.activePrimary}`,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '2rem 2.4rem !important',
        },
    },
    heading: {
        marginBottom: '1rem',
    },
    formInput: {
        marginBottom: '2.4rem',
    },
    inputLabel: {
        marginBottom: '.8rem',
    },
    accordion: {
        boxShadow: 'none',
        zIndex: '1 !important',
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        padding: '0',
        display: 'inline-flex',
    },
    accordionDetail: {
        flexDirection: 'column',
        padding: '0',
    },
    collapse: {
        border: 'none',
    },
    accordionExpanded: {
        margin: '1.2rem 0 0 !important',
    },
    accordionSummaryExpanded: {
        minHeight: 'auto !important',
        margin: '0 0 1rem !important',
    },
    resetButton: {
        minHeight: '4rem',
        padding: '.8rem 1.6rem !important',
        transform: 'translateX(0)',
        '&:hover': {
            backgroundColor: `${v2Colors.action.active} !important`,
            textDecoration: 'none',
        },
    },
    formInputContainer: {
        '&:nth-child(even)': {
            paddingRight: '1.35rem',
        },
        '&:nth-child(odd)': {
            paddingLeft: '1.35rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            '&:nth-child(even)': {
                paddingRight: 0,
            },
            '&:nth-child(odd)': {
                paddingLeft: 0,
            },
        },
    },
} as const;
